<template>
<div class="container notifications-page">
  <h2>{{ $t('notifications.header') }}</h2>
  <div class="action-bar">
    <div class="action-filter" @click="showFilter = true">
      {{ $t('notifications.filter') }}
    </div>
    <div class="action-mark-all-read" @click="markAllRead">
      {{ $t('notifications.mark_as_read') }}
    </div>
  </div>
  <div class="notifications-wrap">
    <NotificationFilters
      :filters="options"
      :showFilter="showFilter"
      @filter-checked="optionChecked"
      @resource-selected="handleResource"
      @resource-id-selected="handleResourceId"
      @close-filter="showFilter = false"
    />
    <div v-if="showEmpty" class="empty">
      {{ $t('notifications.none') }}
    </div>
    <div class="notifications-list">
      <NotificationsItem
        v-for="(notification, index) in notifications"
        :key="index"
        :notification="notification"
        :showInNewTab="true"
        :large="true"
        @mark-read="markRead"
      />
      <Paginator
        v-if="!showEmpty && initLoaded && totalPages > 1"
        :totalPages="totalPages"
        @navigate="page => getNotifications(page)"
      />
    </div>
  </div>
</div>
</template>

<script>
import NotificationFilters from '@/components/notifications/NotificationFilters.vue';
import NotificationsItem from '@/components/notifications/NotificationsItem.vue';
import Paginator from '@/components/nav/Paginator.vue';
import {
  getUserNotifications,
  markNotificationRead,
  markAllNotificationsRead,
} from '@/utils/api';

export default {
  name: 'notifications',
  components: {
    NotificationFilters,
    NotificationsItem,
    Paginator,
  },
  data() {
    const typeCopy = this.$t('notifications.type_checks');
    return {
      notifications: [],
      initLoaded: false,
      perPage: 8,
      page: 1,
      totalPages: -1,
      options: [{
        ...typeCopy,
        checked: true,
        children: typeCopy.children.map(child => ({ ...child, checked: true })),
      }],
      resource: '',
      resourceIds: [],
      showFilter: false,
    };
  },
  computed: {
    optionKeys() {
      const options = this.options[0].children || [];
      const keys = options.reduce((acc, cur) => acc.concat(cur.checked ? [cur.key] : []), []);
      if(keys.length === 0) {
        return null;
      }
      // Empty array gets all notification types more efficiently
      return (keys.length === options.length) ? [] : keys;
    },
    lastPage() {
      return this.totalPages === this.page;
    },
    showEmpty() {
      return (this.notifications.length === 0) && this.initLoaded;
    },
    user() {
      return this.$store.getters.profile || {};
    },
  },
  methods: {
    async optionChecked(data) {
      this.options = data;
      await this.resetNotifications();
    },
    async handleResource(resource) {
      this.resource = resource;
      this.resourceIds = [];
      await this.resetNotifications();
    },
    async handleResourceId(resourceIds) {
      this.resourceIds = resourceIds;
      await this.resetNotifications();
    },
    resetNotifications() {
      this.notifications = [];
      this.totalPages = -1;
      this.initLoaded = false;
      return this.getNotifications(1);
    },
    async getNotifications(page) {
      // No need to make a request if no filters are selected
      if(this.optionKeys) {
        try {
          const { data: { notifications, pagination } } = await getUserNotifications({
            page,
            perPage: this.perPage,
            types: this.optionKeys,
            resource: this.resource,
            resourceIds: this.resourceIds,
          });
          this.page = page;
          this.totalPages = pagination.total_pages;
          this.notifications = this.sortNotifications(notifications);
        } catch(err) {
          console.log(err);
        }
      }
      this.initLoaded = true;
    },
    sortNotifications(notifications) {
      return notifications.sort((note1, note2) => note2.date - note1.date)
        .sort((note1, note2) => note1.read - note2.read);
    },
    markAllRead() {
      markAllNotificationsRead()
        .then(() => {
          this.notifications.map((n) => {
            n.read = true;
            return n;
          });
        })
        .catch((err) => {
          console.log('error marking notification read: ', err);
        });
    },
    markRead(id) {
      const selected = this.notifications.find(n => n.id === id);
      if(selected && !selected.read) {
        markNotificationRead(selected.id)
          .then(() => {
            selected.read = true;
          })
          .catch((err) => {
            console.log('error marking notification read: ', err);
          });
      }
    },
  },
  created() {
    this.resource = this.$t('notifications.created');
    this.resourceIds = this.user.latest_created_projects.map(project => project.id);
    this.getNotifications(this.page);
  },
};
</script>

<style lang="scss">
@import 'widgets';

.notifications-page {
  width: 100%;
  background-color: $white;
  box-sizing: border-box;
  min-height: 96px;

  h2 {
    padding-top: 48px;
    padding-bottom: 32px;
  }
}
.action-bar {
  @include subheading;
  cursor: pointer;
  color: $main-blue;
  display: flex;
  text-transform: uppercase;

  .action-filter {
    display: none;
  }
  .action-mark-all-read {
    margin-left: auto;
  }
}

.notifications-wrap {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  min-height: 400px;

  .notifications-more {
    @include button($main-black, full);
    margin: 32px 0 16px;
    padding: 0;
  }
}

.notifications-list {
  box-sizing: border-box;
  min-width: 0;
  padding-left: 24px;
}
.empty {
  @include h4;
  margin: 24px auto;
}

.paginator {
  margin: 40px auto 100px auto;
  width: 400px;
}

@media (max-width: 578px) {
  .action-bar .action-filter { display: block; }
  .paginator { width: 100%; }
}
</style>
