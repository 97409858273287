<template>
<div class="paginator">
  <CarouselPrev
    :disabled="currentPage == 1"
    @click="onNavigate(currentPage - 1)"
  />
  <div class="pages">
    <span
      v-for="n in totalPages"
      :key="n"
      :class="[{ 'active': currentPage == n }]"
      @click="onNavigate(n)"
    >
      {{ n }}
    </span>
  </div>
  <CarouselNext
    :disabled="currentPage === totalPages"
    @click="onNavigate(currentPage + 1)"
  />
</div>
</template>

<script>
import CarouselNext from '@/components/widget/CarouselNext.vue';
import CarouselPrev from '@/components/widget/CarouselPrev.vue';

export default {
  name: 'paginator',
  components: {
    CarouselNext,
    CarouselPrev,
  },
  props: {
    totalPages: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    onNavigate(page) {
      this.currentPage = page;
      this.$emit('navigate', page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'widgets';

.paginator {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pages {
    span {
      @include h3;
      font-weight: 400;
      color: $grey2;
      cursor: pointer;
      padding: 0 12px;

      &.active {
        color: $main-black;
        font-weight: 600;
      }
    }
  }
}
</style>
