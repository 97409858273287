<template>
<div :class="['filter-panel', { active: showFilter }]">
  <div class="filter-panel-header">
    <Arrow class="arrow" :clickable="true" @click="$emit('close-filter')" />
    <h4>{{ $t("notifications.filter_notifications") }}</h4>
  </div>
  <div class="filter-panel-body">
    <div class="projects">
      {{ $t("notifications.projects") }}
    </div>
    <ProjectNotificationFilters
      @resource-selected="data => $emit('resource-selected', data)"
      @resourceIdSelected="data => $emit('resource-id-selected', data)"
    />
    <div class="notifications-types">
      {{ $t("notifications.types") }}
    </div>
    <CheckboxTree
      :checkboxData="filters"
      @on-check="$emit('filter-checked', $event)"
    />
  </div>
  <button class="btn-apply-filter" @click="$emit('close-filter')">
    <span>{{ $t("done") }}</span>
  </button>
</div>
</template>

<script>
import Arrow from '@/components/svg/Arrow.vue';
import CheckboxTree from '@/components/widget/CheckboxTree.vue';
import ProjectNotificationFilters from '@/components/notifications/ProjectNotificationFilters.vue';

export default {
  name: 'notification-filters',
  components: {
    Arrow,
    CheckboxTree,
    ProjectNotificationFilters,
  },
  props: {
    filters: {
      type: [Array],
      default: () => [],
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.filter-panel {
  background-color: $white;
  min-width: 240px;

  .filter-panel-header,
  .btn-apply-filter {
    display: none;
  }
  .notifications-types {
    @include h5;
    margin-top: 40px;
  }
  .projects {
    @include h5;
  }

  .tree-body {
    margin-left: 8px;
    .p-checkbox {
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 578px) {
  .filter-panel {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    padding: 20px;

    &.active {
      display: flex;
      flex-direction: column;
    }

    .filter-panel-header {
      display: block;
      margin-bottom: 24px;
      text-align: center;
      position: relative;

      .arrow {
        transform: rotate(180deg);
        position: absolute;
        left: 0;
        top: 6px;
      }
    }

    .filter-panel-body {
      flex-grow: 2;
    }

    .btn-apply-filter {
      @include button($main-black, small, primary);
      width: 100%;
    }
  }
}
</style>
