<template>
<div class="project-notification-filters">
  <Radio
    :value="selectedProjectType"
    :radioValue="$t('notifications.created')"
    :hiddenRadio="true"
    @input="checkProjectType('CREATED')"
  >
    {{ $t("notifications.created_projects") }}
  </Radio>
  <div>
    <Checkbox
      v-for="(project, index) in createdProjects"
      :key="`created_${project.id}`"
      class="created-project-item"
      :item="project"
      :onCheck="checked => checkCreated(project.id, index, checked)"
    >
      {{ project.name }}
    </Checkbox>
  </div>
  <Radio
    :value="selectedProjectType"
    :radioValue="$t('notifications.backed')"
    :hiddenRadio="true"
    @input="checkProjectType('BACKED')"
  >
    {{ $t("notifications.backed_projects") }}
  </Radio>
  <div>
    <Checkbox
      v-for="(project, index) in backedProjects"
      :key="`backed_${index}`"
      class="backed-project-item"
      :item="project"
      :onCheck="checked => checkBacked(project.id, index, checked)"
    >
      {{ project.name }}
    </Checkbox>
  </div>
</div>
</template>

<script>
import Radio from '@/components/widget/Radio.vue';
import Checkbox from '@/components/widget/Checkbox.vue';

export default {
  name: 'project-notification-filters',
  components: {
    Radio,
    Checkbox,
  },
  data() {
    return {
      selectedProjectType: 'CREATED',
      createdProjects: [],
      backedProjects: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.profile || {};
    },
  },
  methods: {
    checkProject(projects, item, index, checked) {
      const resourceIdsArray = projects.filter(project => project.checked).map(project => project.id);
      const lastOptionStanding = resourceIdsArray.length === 1 && resourceIdsArray[0] === item;
      if(!lastOptionStanding) {
        this.$set(projects[index], 'checked', checked);
        const checkedResourceIds = projects.filter(id => id.checked).map(project => project.id);
        this.$emit('resource-id-selected', checkedResourceIds);
      }
    },
    checkProjectType(type) {
      if(this.selectedProjectType !== type) {
        this.selectedProjectType = type;
        const backed = type === 'BACKED';

        this.createdProjects.forEach((obj) => {
          obj.checked = !backed;
        });
        this.backedProjects.forEach((obj) => {
          obj.checked = backed;
        });
        this.$emit('resource-selected', type);
      }
    },
    checkCreated(item, index, checked) {
      this.checkProject(this.createdProjects, item, index, checked);
      this.checkProjectType('CREATED');
    },
    checkBacked(item, index, checked) {
      this.checkProject(this.backedProjects, item, index, checked);
      this.checkProjectType('BACKED');
    },
  },
  mounted() {
    const makeItem = checked => project => ({
      label: project.name,
      id: project.id,
      checked,
    });
    this.createdProjects = this.user.latest_created_projects.map(makeItem(true));
    this.backedProjects = this.user.latest_backed_projects.map(makeItem(false));
  },
};
</script>

<style lang="scss">
@import 'widgets';

.project-notification-filters {
  .created-project-item, .backed-project-item {
    margin: 0 0 4px 15px;
    @include subtitle;
    color: $grey2;
  }
  .p-radio {
    margin-top: 12px;
    label {
      font-size: 18px;
      font-weight: 700;
      color: $grey2;
    }
  }
  .p-radio input:checked + label {
    font-weight: 700;
    color: $main-black;
  }
  .p-checkbox .checkbox-text {
    @include button_large_text;
    color: $grey2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
  }
  .checkmark {
    display: none;
  }
  .checkmark.checked + .checkbox-text {
    color: $main-black;
  }
}

</style>
