<template>
<div class="p-checkbox-tree">
  <template v-for="(item, index) in checkboxData">
    <div :key="`${index}_${item.checked}`" class="tree-body">
      <Checkbox
        :item="item"
        :checkedClass="checkedStatus(item)"
        :onCheck="checked => itemChecked(item, index, checked)"
      />
      <checkbox-tree
        v-if="item.children && item.children.length"
        :checkboxData="item.children"
        @on-check="data => subtreeChecked(index, data)"
      />
    </div>
  </template>
</div>
</template>

<script>
// TODO - Root parent checkbox cbecked state is incorrect while manually selected all its children
// TODO - Should correctly get all selected items for nested tree.

import Checkbox from '@/components/widget/Checkbox.vue';

export default {
  name: 'checkbox-tree',
  components: {
    Checkbox,
  },
  props: {
    checkboxData: {
      // Accept either single parent object or array, for convenience
      type: [Array],
      default: () => ([{
        children: [],
      }]),
    },
    onCheck: {
      type: Function,
      default: () => {},
    },
    root: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    itemChecked(item, index, checked) {
      const newItem = this.toggledItem(item, checked);
      const newData = [...this.checkboxData];
      newData.splice(index, 1, newItem);
      this.$emit('on-check', newData);
    },
    subtreeChecked(index, data) {
      const newData = [...this.checkboxData];
      newData.splice(index, 1, {
        ...this.checkboxData[index],
        checked: data.some(child => child.checked),
        children: data,
      });
      this.$emit('on-check', newData);
    },
    checkedStatus(item) {
      let status = '';
      if(!item.children || !item.children.length) {
        status = item.checked ? 'checked' : '';
      } else if(item.children.every(child => child.checked)) {
        status = 'checked';
      } else if(item.children.some(child => child.checked)) {
        status = 'partial';
      }
      return status;
    },
    toggledItem(item, checked) {
      const children = item.children || [];
      return {
        ...item,
        checked,
        children: children.map(child => this.toggledItem(child, checked)),
      };
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

</style>
